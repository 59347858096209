export default {
  CATEGORY_EQUIPMENTS_LIST: "Liste des catégories d'equipements",
  ADD_CATEGORY_EQUIPMENT: "Ajouter une catégorie d'équipement",
  CATEGORY_EQUIPMENT_ADDED: "Catégorie d'équipement ajoutée",
  EDIT_CATEGORY_EQUIPMENT: "Modifier une catégorie d'équipement",
  CATEGORY_EQUIPMENT_UPDATED: "Catégorie d'équipement mis à jour",
  SUB_CATEGORY_EQUIPMENT: "Sous catégorie d'équipement",
  DELETE_THIS_CATEGORY_EQUIPMENT: "Supprimer cette catégorie d'équipement ?",
  CATEGORY_EQUIPMENT_DELETED: "Catégorie d'équipement supprimée",
  PARENT_CATEGORY_EQUIPMENT: "Catégorie d'équipement parente",
  HIERARCHICAL_CATEGORY_EQUIPMENT: "Hiérarchique",
};
