export default {
  PERM_VIEW_MODULE_CAFETERIAS: "Accéder au module cafétéria",
  PERM_VIEW_ANY_CAFETERIAS: "Voir toutes les cafétérias",
  PERM_VIEW_CAFETERIAS: "Voir les détails d'une cafétéria",
  PERM_CREATE_CAFETERIAS: "Créer une cafétéria",
  PERM_EDIT_CAFETERIAS: "Modifier les cafétérias",
  PERM_DELETE_CAFETERIAS: "Supprimer une cafétéria",

  PERM_VIEW_MODULE_ESTABLISHMENTS: "Accéder au module établissement",
  PERM_VIEW_ANY_ESTABLISHMENTS: "Voir tous les établissements",
  PERM_VIEW_ESTABLISHMENTS: "Voir les détails d'un établissement",
  PERM_CREATE_ESTABLISHMENTS: "Créer un établissement",
  PERM_EDIT_ESTABLISHMENTS: "Modifier les établissements",
  PERM_DELETE_ESTABLISHMENTS: "Supprimer un établissement",
  PERM_VIEW_MODULE_ESTABLISHMENTS_DIRECTORY:
    "Accéder au module bottin établissements",

  PERM_VIEW_MODULE_SERVICECENTERS: "Accéder au module centre de service",
  PERM_VIEW_ANY_SERVICECENTERS: "Voir tous les centres de service",
  PERM_VIEW_SERVICECENTERS: "Voir les détails d'un centre de service",
  PERM_CREATE_SERVICECENTERS: "Créer un centre de service",
  PERM_EDIT_SERVICECENTERS: "Modifier les centres de service",
  PERM_DELETE_SERVICECENTERS: "Supprimer un centre de service",

  PERM_VIEW_MODULE_IMPORTS: "Accéder au module import",
  PERM_VIEW_ANY_IMPORTS: "Voir tous les import",
  PERM_VIEW_IMPORTS: "Voir les détails d'un import",
  PERM_CREATE_IMPORTS: "Créer un import",
  PERM_EDIT_IMPORTS: "Modifier les import",
  PERM_DELETE_IMPORTS: "Supprimer un import",

  PERM_VIEW_MODULE_STOCK_MOVEMENTS: "Accéder au module mouvements de stock",
  PERM_VIEW_ANY_STOCK_MOVEMENTS: "Voir tous les mouvements de stock",
  PERM_VIEW_STOCK_MOVEMENTS: "Voir les détails d'un mouvement de stock",
  PERM_CREATE_STOCK_MOVEMENTS: "Créer un mouvement de stock",
  PERM_CREATE_STOCK_MOVEMENTS_FROM_EXISTING_QUANTITY: "Créer un mouvement de stock à partir des quantités existantes",
  PERM_EDIT_STOCK_MOVEMENTS: "Modifier les mouvements de stock",
  PERM_DELETE_STOCK_MOVEMENTS: "Supprimer un mouvement de stock",

  PERM_VIEW_MODULE_WAREHOUSES: "Accéder au module entrepôts",
  PERM_VIEW_ANY_WAREHOUSES: "Voir tous les entrepôts",
  PERM_VIEW_WAREHOUSES: "Voir les détails d'un entrepôt",
  PERM_CREATE_WAREHOUSES: "Créer un entrepôt",
  PERM_EDIT_WAREHOUSES: "Modifier les entrepôts",
  PERM_DELETE_WAREHOUSES: "Supprimer un entrepôt",

  PERM_VIEW_MODULE_RESELLER_PAYMENTS: "Accéder au module paiements revendeur",
  PERM_VIEW_ANY_RESELLER_PAYMENTS: "Voir tous les paiements revendeur",
  PERM_VIEW_RESELLER_PAYMENTS: "Voir les détails d'un paiement revendeur",
  PERM_CREATE_RESELLER_PAYMENTS: "Créer un paiement revendeur",
  PERM_EDIT_RESELLER_PAYMENTS: "Modifier les paiements revendeur",
  PERM_DELETE_RESELLER_PAYMENTS: "Supprimer un paiement revendeur",

  PERM_VIEW_MODULE_RESELLER_INVOICES: "Accéder au module factures revendeur",
  PERM_VIEW_ANY_RESELLER_INVOICES: "Voir toutes les factures revendeur",
  PERM_VIEW_RESELLER_INVOICES: "Voir les détails d'une facture revendeur",
  PERM_CREATE_RESELLER_INVOICES: "Créer une facture revendeur",
  PERM_EDIT_RESELLER_INVOICES: "Modifier les factures revendeur",
  PERM_DELETE_RESELLER_INVOICES: "Supprimer une facture revendeur",

  PERM_VIEW_MODULE_RESELLER_SERVICES: "Accéder au module services revendeur",
  PERM_VIEW_ANY_RESELLER_SERVICES: "Voir tous les services revendeur",
  PERM_VIEW_RESELLER_SERVICES: "Voir les détails d'un service revendeur",
  PERM_CREATE_RESELLER_SERVICES: "Créer un service revendeur",
  PERM_EDIT_RESELLER_SERVICES: "Modifier les services revendeur",
  PERM_DELETE_RESELLER_SERVICES: "Supprimer un service revendeur",

  PERM_VIEW_MODULE_RESELLER_PRODUCTS: "Accéder au module produits revendeur",
  PERM_VIEW_ANY_RESELLER_PRODUCTS: "Voir tous les produits revendeur",
  PERM_VIEW_RESELLER_PRODUCTS: "Voir les détails d'un produit revendeur",
  PERM_CREATE_RESELLER_PRODUCTS: "Créer un produit revendeur",
  PERM_EDIT_RESELLER_PRODUCTS: "Modifier les produits revendeur",
  PERM_DELETE_RESELLER_PRODUCTS: "Supprimer un produit revendeur",

  PERM_VIEW_MODULE_SALES_PAYMENTS: "Accéder au module paiements vente",
  PERM_VIEW_ANY_SALES_PAYMENTS: "Voir tous les paiements vente",
  PERM_VIEW_SALES_PAYMENTS: "Voir les détails d'un paiement vente",
  PERM_CREATE_SALES_PAYMENTS: "Créer un paiement vente",
  PERM_EDIT_SALES_PAYMENTS: "Modifier les paiements vente",
  PERM_DELETE_SALES_PAYMENTS: "Supprimer un paiement vente",

  PERM_VIEW_MODULE_SALES_DELIVERIES: "Accéder au module livraisons vente",
  PERM_VIEW_ANY_SALES_DELIVERIES: "Voir toutes les livraisons vente",
  PERM_VIEW_SALES_DELIVERIES: "Voir les détails d'une livraison vente",
  PERM_CREATE_SALES_DELIVERIES: "Créer une livraison vente",
  PERM_EDIT_SALES_DELIVERIES: "Modifier les livraisons vente",
  PERM_DELETE_SALES_DELIVERIES: "Supprimer une livraison vente",

  PERM_VIEW_MODULE_SALES_INVOICES: "Accéder au module factures vente",
  PERM_VIEW_ANY_SALES_INVOICES: "Voir toutes les factures vente",
  PERM_VIEW_SALES_INVOICES: "Voir les détails d'une facture vente",
  PERM_CREATE_SALES_INVOICES: "Créer une facture vente",
  PERM_EDIT_SALES_INVOICES: "Modifier les factures vente",
  PERM_DELETE_SALES_INVOICES: "Supprimer une facture vente",

  PERM_VIEW_MODULE_SALES_ORDERS: "Accéder au module soumission",
  PERM_VIEW_ANY_SALES_ORDERS: "Voir toutes les soumissions",
  PERM_VIEW_SALES_ORDERS: "Voir les détails d'une soumission",
  PERM_CREATE_SALES_ORDERS: "Créer une soumission",
  PERM_EDIT_SALES_ORDERS: "Modifier les soumissions",
  PERM_DELETE_SALES_ORDERS: "Supprimer une soumission",

  PERM_VIEW_MODULE_PURCHASES_PAYMENTS: "Accéder au module paiements achat",
  PERM_VIEW_ANY_PURCHASES_PAYMENTS: "Voir tous les paiements achat",
  PERM_VIEW_PURCHASES_PAYMENTS: "Voir les détails d'un paiement achat",
  PERM_CREATE_PURCHASES_PAYMENTS: "Créer un paiement achat",
  PERM_EDIT_PURCHASES_PAYMENTS: "Modifier les paiements achat",
  PERM_DELETE_PURCHASES_PAYMENTS: "Supprimer un paiement achat",

  PERM_VIEW_MODULE_PURCHASES_DELIVERIES: "Accéder au module livraisons achat",
  PERM_VIEW_ANY_PURCHASES_DELIVERIES: "Voir toutes les livraisons achat",
  PERM_VIEW_PURCHASES_DELIVERIES: "Voir les détails d'une livraison achat",
  PERM_CREATE_PURCHASES_DELIVERIES: "Créer une livraison achat",
  PERM_EDIT_PURCHASES_DELIVERIES: "Modifier les livraisons achat",
  PERM_DELETE_PURCHASES_DELIVERIES: "Supprimer une livraison achat",

  PERM_VIEW_MODULE_PURCHASES_INVOICES: "Accéder au module factures achat",
  PERM_VIEW_ANY_PURCHASES_INVOICES: "Voir toutes les factures achat",
  PERM_VIEW_PURCHASES_INVOICES: "Voir les détails d'une facture achat",
  PERM_CREATE_PURCHASES_INVOICES: "Créer une facture achat",
  PERM_EDIT_PURCHASES_INVOICES: "Modifier les factures achat",
  PERM_DELETE_PURCHASES_INVOICES: "Supprimer une facture achat",

  PERM_VIEW_MODULE_PURCHASES_ORDERS: "Accéder au module commandes achat",
  PERM_VIEW_ANY_PURCHASES_ORDERS: "Voir toutes les commandes achat",
  PERM_VIEW_PURCHASES_ORDERS: "Voir les détails d'une commande achat",
  PERM_CREATE_PURCHASES_ORDERS: "Créer une commande achat",
  PERM_EDIT_PURCHASES_ORDERS: "Modifier les commandes achat",
  PERM_DELETE_PURCHASES_ORDERS: "Supprimer une commande achat",

  PERM_VIEW_MODULE_FOLDERS: "Accéder au module dossiers",
  PERM_VIEW_ANY_FOLDERS: "Voir tous les dossiers",
  PERM_VIEW_FOLDERS: "Voir les détails d'un dossier",
  PERM_CREATE_FOLDERS: "Créer un dossier",
  PERM_EDIT_FOLDERS: "Modifier les dossiers",
  PERM_DELETE_FOLDERS: "Supprimer un dossier",
  PERM_MANAGE_ACCESS_FOLDERS: "Gérer les permissions",
  PERM_MANAGE_LOCKED_FOLDERS: "Gérer les dossiers systemes",

  PERM_VIEW_MODULE_FILES: "Accéder au module fichiers",
  PERM_VIEW_ANY_FILES: "Voir tous les fichiers",
  PERM_VIEW_FILES: "Voir les détails d'un fichier",
  PERM_CREATE_FILES: "Créer un fichier",
  PERM_EDIT_FILES: "Modifier les fichiers",
  PERM_DELETE_FILES: "Supprimer un fichier",
  PERM_MANAGE_ACCESS_FILES: "Gérer les permissions",

  PERM_VIEW_MODULE_LOCATIONS: "Accéder au module emplacements",
  PERM_VIEW_ANY_LOCATIONS: "Voir tous emplacements",
  PERM_VIEW_LOCATIONS: "Voir les détails d'un emplacement",
  PERM_CREATE_LOCATIONS: "Créer un emplacement",
  PERM_EDIT_LOCATIONS: "Modifier les emplacements",
  PERM_DELETE_LOCATIONS: "Supprimer un emplacement",
  PERM_EDIT_HIS_LOCATIONS: "Modifier ses emplacements",

  PERM_VIEW_MODULE_SUPPLIERS: "Accéder au module fournisseurs",
  PERM_VIEW_ANY_SUPPLIERS: "Voir tous fournisseurs",
  PERM_VIEW_SUPPLIERS: "Voir les détails d'un fournisseur",
  PERM_CREATE_SUPPLIERS: "Créer un fournisseur",
  PERM_EDIT_SUPPLIERS: "Modifier les fournisseurs",
  PERM_DELETE_SUPPLIERS: "Supprimer un fournisseur",

  PERM_VIEW_MODULE_CONTACTS: "Accéder au module contacts",
  PERM_VIEW_ANY_CONTACTS: "Voir tous contacts",
  PERM_VIEW_CONTACTS: "Voir les détails d'un contact",
  PERM_CREATE_CONTACTS: "Créer un contact",
  PERM_EDIT_CONTACTS: "Modifier les contacts",
  PERM_DELETE_CONTACTS: "Supprimer un contact",

  PERM_VIEW_MODULE_PAYMENTS: "Accéder au module paiements",
  PERM_VIEW_ANY_PAYMENTS: "Voir tous les paiements",
  PERM_VIEW_PAYMENTS: "Voir les détails d'un paiement",
  PERM_CREATE_PAYMENTS: "Créer un paiement",

  PERM_VIEW_MODULE_SUBSCRIPTIONS: "Accéder au module inscriptions",
  PERM_VIEW_ANY_SUBSCRIPTIONS: "Voir toutes les inscriptions",
  PERM_VIEW_SUBSCRIPTIONS: "Voir les détails d'une inscription",
  PERM_CREATE_SUBSCRIPTIONS: "Créer une inscription",
  PERM_EDIT_SUBSCRIPTIONS: "Modifier les inscriptions",
  PERM_DELETE_SUBSCRIPTIONS: "Supprimer une inscription",

  PERM_VIEW_MODULE_PACKAGES: "Accéder au module forfaits",
  PERM_VIEW_ANY_PACKAGES: "Voir tous les forfaits",
  PERM_VIEW_PACKAGES: "Voir les détails d'un forfait",
  PERM_CREATE_PACKAGES: "Créer un forfait",
  PERM_EDIT_PACKAGES: "Modifier les forfaits",
  PERM_DELETE_PACKAGES: "Supprimer un forfait",

  PERM_VIEW_MODULE_CUSTOMERS: "Accéder au module clients",
  PERM_VIEW_ANY_CUSTOMERS: "Voir tous les clients",
  PERM_VIEW_CUSTOMERS: "Voir les détails d'un client",
  PERM_CREATE_CUSTOMERS: "Créer un client",
  PERM_EDIT_CUSTOMERS: "Modifier les clients",
  PERM_DELETE_CUSTOMERS: "Supprimer un client",

  PERM_VIEW_MODULE_INVOICES: "Accéder au module factures",
  PERM_VIEW_ANY_INVOICES: "Voir toutes les factures",
  PERM_VIEW_INVOICES: "Voir les détails d'une facture",
  PERM_CREATE_INVOICES: "Créer une facture",
  PERM_EDIT_INVOICES: "Modifier les factures",
  PERM_DELETE_INVOICES: "Supprimer une facture",

  PERM_VIEW_MODULE_PRODUCTS: "Accéder au module produits",
  PERM_VIEW_ANY_PRODUCTS: "Voir tous les produits",
  PERM_VIEW_PRODUCTS: "Voir les détails des produits",
  PERM_CREATE_PRODUCTS: "Créer un produit",
  PERM_EDIT_PRODUCTS: "Modifier les produits",
  PERM_DELETE_PRODUCTS: "Supprimer les produits",

  PERM_VIEW_MODULE_ORGANIZATIONS: "Accéder au module organisations",
  PERM_VIEW_ANY_ORGANIZATIONS: "Voir toutes les organisations",
  PERM_VIEW_ORGANIZATIONS: "Voir les détails des organisations",
  PERM_CREATE_ORGANIZATIONS: "Créer une organisation",
  PERM_EDIT_ORGANIZATIONS: "Modifier les organisations",
  PERM_EDIT_OWN_ORGANIZATIONS: "Modifier son organisation",
  PERM_DELETE_ORGANIZATIONS: "Supprimer les organisations",
  PERM_EDIT_HIS_ORGANIZATIONS:
    "Modifier l'organisation à laquelle il appartient",

  PERM_VIEW_MODULE_RESELLERS: "Accéder au module revendeurs",
  PERM_VIEW_ANY_RESELLERS: "Voir tous les revendeurs",
  PERM_VIEW_RESELLERS: "Voir les détails des revendeurs",
  PERM_CREATE_RESELLERS: "Créer un revendeur",
  PERM_EDIT_RESELLERS: "Modifier les revendeurs",
  PERM_EDIT_OWN_RESELLERS: "Modifier son revendeur",
  PERM_DELETE_RESELLERS: "Supprimer les revendeurs",

  PERM_VIEW_MODULE_USERS: "Accéder au module utilisateurs",
  PERM_VIEW_ANY_USERS: "Voir tous les utilisateurs",
  PERM_VIEW_USERS: "Voir les détails des utilisateurs",
  PERM_CREATE_USERS: "Créer un utilisateur",
  PERM_EDIT_USERS: "Modifier les utilisateurs",
  PERM_DELETE_USERS: "Supprimer les utilisateurs",

  PERM_VIEW_MODULE_ROLES: "Accéder au module rôles",
  PERM_VIEW_ANY_ROLES: "Voir tous les rôles",
  PERM_VIEW_ROLES: "Voir les détails des rôles",
  PERM_CREATE_ROLES: "Créer un rôle",
  PERM_EDIT_ROLES: "Modifier les rôles",
  PERM_DELETE_ROLES: "Supprimer les rôles",

  PERM_VIEW_ANY_PERMISSIONS: "Voir toutes les permissions",
  PERM_VIEW_PERMISSIONS: "Voir les détails des permissions",

  PERM_VIEW_ANY_LOGS: "Voir tous les logs",
  PERM_VIEW_LOGS: "Voir les détails d'un log",

  PERM_VIEW_APP_ADMIN: "Voir l'app admin",
  PERM_VIEW_APP_CRM: "Voir l'app crm",
  PERM_VIEW_APP_FILE_EXPLORER: "Voir l'app explorateur de fichiers",
  PERM_VIEW_APP_PURCHASES: "Voir l'app achats",
  PERM_VIEW_APP_SALES: "Voir l'app ventes",
  PERM_VIEW_APP_DEFAULT: "Voir l'app par défaut",
  PERM_VIEW_APP_INVENTORY: "Voir l'app inventaire",
  PERM_VIEW_APP_RESELLER: "Voir l'app revendeur",
  PERM_VIEW_APP_CAFETERIA: "Voir l'app cafétéria",
  PERM_VIEW_APP_REPORTS: "Voir les rapports",

  PERM_VIEW_MODULE_SUPPLY_RULES: "Accéder au module règles d'approvisionnement",
  PERM_VIEW_ANY_SUPPLY_RULES: "Voir tous les règles d'approvisionnement",
  PERM_VIEW_SUPPLY_RULES: "Voir les détails des règles d'approvisionnement",
  PERM_CREATE_SUPPLY_RULES: "Créer une règle d'approvisionnement",
  PERM_EDIT_SUPPLY_RULES: "Modifier les règles d'approvisionnement",
  PERM_DELETE_SUPPLY_RULES: "Supprimer les règles d'approvisionnement",

  PERM_VIEW_MODULE_CATEGORIES: "Accéder au module catégories",
  PERM_VIEW_ANY_CATEGORIES: "Voir tous les catégories",
  PERM_VIEW_CATEGORIES: "Voir les détails d'une catégorie",
  PERM_CREATE_CATEGORIES: "Créer une catégorie",
  PERM_EDIT_CATEGORIES: "Modifier les catégories",
  PERM_DELETE_CATEGORIES: "Supprimer une catégorie",

  PERM_VIEW_MODULE_TAXES: "Accéder au module taxes",
  PERM_VIEW_ANY_TAXES: "Voir tous les taxes",
  PERM_VIEW_TAXES: "Voir les détails d'une taxe",
  PERM_CREATE_TAXES: "Créer une taxe",
  PERM_EDIT_TAXES: "Modifier les taxes",
  PERM_DELETE_TAXES: "Supprimer une taxe",

  PERM_VIEW_MODULE_PAYMENT_TERMS: "Accéder au module termes de paiements",
  PERM_VIEW_ANY_PAYMENT_TERMS: "Voir tous les termes de paiements",
  PERM_VIEW_PAYMENT_TERMS: "Voir les détails d'un terme de paiement",
  PERM_CREATE_PAYMENT_TERMS: "Créer un terme de paiement",
  PERM_EDIT_PAYMENT_TERMS: "Modifier les termes de paiements",
  PERM_DELETE_PAYMENT_TERMS: "Supprimer un terme de paiement",

  PERM_VIEW_MODULE_OPTIONS: "Accéder au module paramètres",
  PERM_VIEW_ANY_OPTIONS: "Voir les paramètres",
  PERM_EDIT_OPTIONS: "Modifier les paramètres",

  PERM_VIEW_MODULE_EVENTS: "Accéder au module evenements",
  PERM_VIEW_ANY_EVENTS: "Voir tous evenements",
  PERM_VIEW_EVENTS: "Voir les détails d'un evenement",
  PERM_CREATE_EVENTS: "Créer un evenement",
  PERM_EDIT_EVENTS: "Modifier les evenements",
  PERM_DELETE_EVENTS: "Supprimer un evenement",

  PERM_VIEW_MODULE_CASH_REGISTERS: "Accéder au module caisses",
  PERM_VIEW_ANY_CASH_REGISTERS: "Voir toutes caisses",
  PERM_VIEW_CASH_REGISTERS: "Voir les détails d'une caisse",
  PERM_CREATE_CASH_REGISTERS: "Créer une caisse",
  PERM_EDIT_CASH_REGISTERS: "Modifier les caisses",
  PERM_DELETE_CASH_REGISTERS: "Supprimer une caisse",

  PERM_VIEW_MODULE_CASH_REGISTER_READINGS: "Accéder au module lecture de caisses",
  PERM_VIEW_ANY_CASH_REGISTER_READINGS: "Voir toutes les lecture de caisses",
  PERM_VIEW_CASH_REGISTER_READINGS: "Voir les détails d'une lecture de caisse",
  PERM_CREATE_CASH_REGISTER_READINGS: "Créer une lecture de caisse",
  PERM_EDIT_CASH_REGISTER_READINGS: "Modifier les lecture de caisses",
  PERM_DELETE_CASH_REGISTER_READINGS: "Supprimer une lecture de caisse",

  PERM_VIEW_MODULE_CASH_REGISTER_READING_ITEMS: "Accéder au module lecture de produits de caisses",
  PERM_VIEW_ANY_CASH_REGISTER_READING_ITEMS: "Voir toutes les lecture de produits de caisses",
  PERM_VIEW_CASH_REGISTER_READING_ITEMS: "Voir les détails d'une lecture de produit d'une caisse",
  PERM_CREATE_CASH_REGISTER_READING_ITEMS: "Créer une lecture de produit d'une caisse",
  PERM_EDIT_CASH_REGISTER_READING_ITEMS: "Modifier les lecture de produit de caisses",
  PERM_DELETE_CASH_REGISTER_READING_ITEMS: "Supprimer une lecture de produit de caisse",
  
  PERM_VIEW_REPORTS_CASH_REGISTER_READINGS: "Rapport lectures de caisses",
  PERM_VIEW_REPORTS_CASH_REGISTERS: "Rapport des caisses",


  PERM_VIEW_MODULE_EQUIPMENTS: "Accéder au module équipements",
  PERM_VIEW_ANY_EQUIPMENTS: "Voir tous les équiments",
  PERM_VIEW_EQUIPMENTS: "Voir les détails d'un équipement",
  PERM_CREATE_EQUIPMENTS: "Créer un équipement",
  PERM_EDIT_EQUIPMENTS: "Modifier les équipements",
  PERM_DELETE_EQUIPMENTS: "Supprimer un équipement",


  PERM_VIEW_MODULE_CATEGORY_EQUIPMENTS: "Accéder au module catégorie d'équipement",
  PERM_VIEW_ANY_CATEGORY_EQUIPMENTS: "Voir tous les catégories d'équiment",
  PERM_VIEW_CATEGORY_EQUIPMENTS: "Voir les détails d'un équipement",
  PERM_CREATE_CATEGORY_EQUIPMENTS: "Créer une catégorie d'équipement",
  PERM_EDIT_CATEGORY_EQUIPMENTS: "Modifier les catégories d'équipement",
  PERM_DELETE_CATEGORY_EQUIPMENTS: "Supprimer une catégorie d équipement",
};
