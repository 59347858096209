import DashboardModuleLayout from "@/views/Pages/DashboardModule/DashboardModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";

// const Dashboard = () =>
//   import("@/views/Pages/DashboardModule/Dashboard/Dashboard.vue");
const WidgetsDashboard = () =>
  import("@/views/Pages/DashboardModule/Dashboard/WidgetsDashboard.vue");

const SupplyRuleView = () =>
  import(
    "@/views/Pages/DashboardModule/Dashboard/SupplyRule/SupplyRuleView.vue"
  );

const EstablishmentDirectoryPage = () =>
  import(
    "@/views/Pages/DashboardModule/EstablishmentsDirectory/EstablishmentDirectoryPage.vue"
  );

const EventsPage = () =>
  import(
    "@/views/Pages/DashboardModule/Events/EventsPage.vue"
  );

const CashRegisterReadingsReportsPage = () =>
  import(
    "@/views/Pages/DashboardModule/Reports/CashRegisterReadingsReportsPage.vue"
  );

const CashRegistersReportsPage = () =>
  import(
    "@/views/Pages/DashboardModule/Reports/CashRegistersReportsPage.vue"
  );

let dashBoardsPages = {
  path: "",
  component: DashboardModuleLayout,
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: WidgetsDashboard,
      meta: { middleware: authenticated },
    },
    {
      path: "establishments-directory",
      name: "EstablishmentDirectory",
      component: EstablishmentDirectoryPage,
      meta: { middleware: authenticated },
    },
    {
      path: "supply-rule",
      name: "Supply-Rule",
      component: SupplyRuleView,
      meta: { middleware: authenticated },
    },
    {
      path: "events",
      name: "events",
      component: EventsPage,
      meta: { middleware: authenticated },
    },
  ],
};

let reportsPages = {
  path: "reports",
  component: DashboardModuleLayout,
  children: [
    {
      path: "cashregister-readings",
      name: "CashRegisterReadingsReportsPage",
      component: CashRegisterReadingsReportsPage,
      meta: { middleware: authenticated },
    },
    {
      path: "cashregisters",
      name: "CashRegistersReportsPage",
      component: CashRegistersReportsPage,
      meta: { middleware: authenticated },
    },
  ],
};

const dashboardModuleRoutes = [dashBoardsPages, reportsPages];

export default dashboardModuleRoutes;
