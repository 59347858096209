import permissions from "../constants/permissions";
import SalesModuleLayout from "@/views/Pages/SalesModule/SalesModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/ListSalesOrderPage.vue"
  );
const AddOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/AddSalesOrderPage.vue"
  );
const EditOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/EditSalesOrderPage.vue"
  );
const ViewOrderPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesOrderManagement/ViewSalesOrderPage.vue"
  );

const ListInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/ListSalesInvoicePage.vue"
  );
const AddInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/AddSalesInvoicePage.vue"
  );
const EditInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/EditSalesInvoicePage.vue"
  );
const ViewInvoicePage = () =>
  import(
    "@/views/Pages/SalesModule/SalesInvoiceManagement/ViewSalesInvoicePage.vue"
  );

const ListDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/ListSalesDeliveryPage.vue"
  );
const AddDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/AddSalesDeliveryPage.vue"
  );
const EditDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/EditSalesDeliveryPage.vue"
  );
const ViewDeliveryPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesDeliveryManagement/ViewSalesDeliveryPage.vue"
  );

const ListPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/ListSalesPaymentPage.vue"
  );
const AddPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/AddSalesPaymentPage.vue"
  );
const EditPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/EditSalesPaymentPage.vue"
  );
const ViewPaymentPage = () =>
  import(
    "@/views/Pages/SalesModule/SalesPaymentManagement/ViewSalesPaymentPage.vue"
  );

const SalesPage = () => import("@/views/Pages/SalesModule/SalesPage.vue");




const ListCashRegisterPage = () =>
  import(
    "@/views/Pages/SalesModule/CashRegisterManagement/ListCashRegisterPage.vue"
  );
const AddCashRegisterPage = () =>
  import(
    "@/views/Pages/SalesModule/CashRegisterManagement/AddCashRegisterPage.vue"
  );
const EditCashRegisterPage = () =>
  import(
    "@/views/Pages/SalesModule/CashRegisterManagement/EditCashRegisterPage.vue"
  );
const ViewCashRegisterPage = () =>
  import(
    "@/views/Pages/SalesModule/CashRegisterManagement/ViewCashRegisterPage.vue"
  );


const ListCashRegisterReadingPage = () =>
import(
  "@/views/Pages/SalesModule/CashRegisterReadingManagement/ListCashRegisterReadingPage.vue"
);
const AddCashRegisterReadingPage = () =>
import(
  "@/views/Pages/SalesModule/CashRegisterReadingManagement/AddCashRegisterReadingPage.vue"
);
const EditCashRegisterReadingPage = () =>
import(
  "@/views/Pages/SalesModule/CashRegisterReadingManagement/EditCashRegisterReadingPage.vue"
);
const ViewCashRegisterReadingPage = () =>
import(
  "@/views/Pages/SalesModule/CashRegisterReadingManagement/ViewCashRegisterReadingPage.vue"
);

let orderManagementPages = {
  path: "orders",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesOrders",
      components: { default: ListOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_ORDERS,
      },
    },
    {
      path: "add",
      name: "Add SalesOrder",
      components: { default: AddOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_ORDERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesOrder",
      components: { default: EditOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_ORDERS,
      },
    },
    {
      path: "view/:id",
      name: "View SalesOrder",
      components: { default: ViewOrderPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_ORDERS,
      },
    },
  ],
};

let invoiceManagementPages = {
  path: "invoices",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesInvoices",
      components: { default: ListInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_INVOICES,
      },
    },
    {
      path: "add",
      name: "Add SalesInvoice",
      components: { default: AddInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_INVOICES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesInvoice",
      components: { default: EditInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_INVOICES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesInvoice",
      components: { default: ViewInvoicePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_INVOICES,
      },
    },
  ],
};

let deliveryManagementPages = {
  path: "deliveries",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesDeliveries",
      components: { default: ListDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add SalesDelivery",
      components: { default: AddDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesDelivery",
      components: { default: EditDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesDelivery",
      components: { default: ViewDeliveryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_DELIVERIES,
      },
    },
  ],
};

let paymentManagementPages = {
  path: "payments",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List SalesPayments",
      components: { default: ListPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SALES_DELIVERIES,
      },
    },
    {
      path: "add",
      name: "Add SalesPayment",
      components: { default: AddPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SALES_DELIVERIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit SalesPayment",
      components: { default: EditPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SALES_DELIVERIES,
      },
    },
    {
      path: "view/:id",
      name: "View SalesPayment",
      components: { default: ViewPaymentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SALES_DELIVERIES,
      },
    },
  ],
};
let cashRegistersManagementPages = {
  path: "cash-registers",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List CashRegisters",
      components: { default: ListCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CASH_REGISTERS,
      },
    },
    {
      path: "add",
      name: "Add CashRegister",
      components: { default: AddCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CASH_REGISTERS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit CashRegister",
      components: { default: EditCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CASH_REGISTERS,
      },
    },
    {
      path: "view/:id",
      name: "View CashRegister",
      components: { default: ViewCashRegisterPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CASH_REGISTERS,
      },
    },
  ],
};
let cashRegisterReadingsManagementPages = {
  path: "cash-register-readings",
  component: SalesModuleLayout,
  children: [
    {
      path: "",
      name: "List CashRegisterReadings",
      components: { default: ListCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "add",
      name: "Add CashRegisterReading",
      components: { default: AddCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit CashRegisterReading",
      components: { default: EditCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CASH_REGISTER_READINGS,
      },
    },
    {
      path: "view/:id",
      name: "View CashRegisterReading",
      components: { default: ViewCashRegisterReadingPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CASH_REGISTER_READINGS,
      },
    },
  ],
};

const salesModuleRoutes = [
  {
    path: "",
    component: SalesModuleLayout,
    children: [
      {
        path: "",
        name: "Sales Page",
        components: { default: SalesPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  orderManagementPages,
  invoiceManagementPages,
  deliveryManagementPages,
  paymentManagementPages,
  cashRegistersManagementPages,
  cashRegisterReadingsManagementPages
];

export default salesModuleRoutes;
