import permissions from "../constants/permissions";
import InventoryModuleLayout from "@/views/Pages/InventoryModule/InventoryModuleLayout.vue";

// import middleware
import authenticated from "@/middleware/authenticated";
import authorized from "@/middleware/authorized";

// import pages
const ListProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/ListProductPage.vue");
const AddProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/AddProductPage.vue");
const EditProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/EditProductPage.vue");
const ViewProductPage = () =>
  import("@/views/Pages/InventoryModule/ProductManagement/ViewProductPage.vue");

const ListUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/ListUnitOfMeasurePage.vue"
  );
const AddUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/AddUnitOfMeasurePage.vue"
  );
const EditUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/EditUnitOfMeasurePage.vue"
  );
const ViewUnitOfMeasurePage = () =>
  import(
    "@/views/Pages/InventoryModule/UnitOfMeasureManagement/ViewUnitOfMeasurePage.vue"
  );

const ListWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/ListWarehousePage.vue"
  );
const AddWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/AddWarehousePage.vue"
  );
const EditWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/EditWarehousePage.vue"
  );
const ViewWarehousePage = () =>
  import(
    "@/views/Pages/InventoryModule/WarehouseManagement/ViewWarehousePage.vue"
  );

const ListStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/ListStockMovementPage.vue"
  );
const AddStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/AddStockMovementPage.vue"
  );
const EditStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/EditStockMovementPage.vue"
  );
const ViewStockMovementPage = () =>
  import(
    "@/views/Pages/InventoryModule/StockMovementManagement/ViewStockMovementPage.vue"
  );

const ListCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/ListCategoryPage.vue"
  );
const AddCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/AddCategoryPage.vue"
  );
const EditCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/EditCategoryPage.vue"
  );
const ViewCategoryPage = () =>
  import(
    "@/views/Pages/InventoryModule/CategoryManagement/ViewCategoryPage.vue"
  );
const InventoryPage = () =>
  import("@/views/Pages/InventoryModule/InventoryPage.vue");

const ListSupplyRulePage = () =>
  import(
    "@/views/Pages/InventoryModule/SupplyRuleManagement/ListSupplyRulePage.vue"
  );
const AddSupplyRulePage = () =>
  import(
    "@/views/Pages/InventoryModule/SupplyRuleManagement/AddSupplyRulePage.vue"
  );
const EditSupplyRulePage = () =>
  import(
    "@/views/Pages/InventoryModule/SupplyRuleManagement/EditSupplyRulePage.vue"
  );
const ViewSupplyRulePage = () =>
  import(
    "@/views/Pages/InventoryModule/SupplyRuleManagement/ViewSupplyRulePage.vue"
  );


  const ListCategoryEquipmentPage = () =>
    import(
      "@/views/Pages/InventoryModule/CategoryEquipmentManagement/ListCategoryEquipmentPage.vue"
    );
  const AddCategoryEquipmentPage = () =>
    import(
      "@/views/Pages/InventoryModule/CategoryEquipmentManagement/AddCategoryEquipmentPage.vue"
    );
  const EditCategoryEquipmentPage = () =>
    import(
      "@/views/Pages/InventoryModule/CategoryEquipmentManagement/EditCategoryEquipmentPage.vue"
    );
  const ViewCategoryEquipmentPage = () =>
    import(
      "@/views/Pages/InventoryModule/CategoryEquipmentManagement/ViewCategoryEquipmentPage.vue"
    );


    const ListEquipmentPage = () =>
      import(
        "@/views/Pages/InventoryModule/EquipmentManagement/ListEquipmentPage.vue"
      );
    const AddEquipmentPage = () =>
      import(
        "@/views/Pages/InventoryModule/EquipmentManagement/AddEquipmentPage.vue"
      );
    const EditEquipmentPage = () =>
      import(
        "@/views/Pages/InventoryModule/EquipmentManagement/EditEquipmentPage.vue"
      );
    const ViewEquipmentPage = () =>
      import(
        "@/views/Pages/InventoryModule/EquipmentManagement/ViewEquipmentPage.vue"
      );
  



let productManagementPages = {
  path: "products",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Products",
      components: { default: ListProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add Product",
      components: { default: AddProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Product",
      components: { default: EditProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View Product",
      components: { default: ViewProductPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};

let supplyRuleManagementPages = {
  path: "supply-rules",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Supply Rules",
      components: { default: ListSupplyRulePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_SUPPLY_RULES,
      },
    },
    {
      path: "add",
      name: "Add Supply Rule",
      components: { default: AddSupplyRulePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_SUPPLY_RULES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Supply Rule",
      components: { default: EditSupplyRulePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_SUPPLY_RULES,
      },
    },
    {
      path: "view/:id",
      name: "View Supply Rule",
      components: { default: ViewSupplyRulePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_SUPPLY_RULES,
      },
    },
  ],
};

let warehouseManagementPages = {
  path: "warehouses",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Warehouses",
      components: { default: ListWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_WAREHOUSES,
      },
    },
    {
      path: "add",
      name: "Add Warehouse",
      components: { default: AddWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_WAREHOUSES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Warehouse",
      components: { default: EditWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_WAREHOUSES,
      },
    },
    {
      path: "view/:id",
      name: "View Warehouse",
      components: { default: ViewWarehousePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_WAREHOUSES,
      },
    },
  ],
};

let categoryManagementPages = {
  path: "categories",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Categories",
      components: { default: ListCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CATEGORIES,
      },
    },
    {
      path: "add",
      name: "Add Category",
      components: { default: AddCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CATEGORIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Category",
      components: { default: EditCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CATEGORIES,
      },
    },
    {
      path: "view/:id",
      name: "View Category",
      components: { default: ViewCategoryPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CATEGORIES,
      },
    },
  ],
};

let stockMovementManagementPages = {
  path: "stock-movements",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List StockMovements",
      components: { default: ListStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_STOCK_MOVEMENTS,
      },
    },
    {
      path: "add",
      name: "Add StockMovement",
      components: { default: AddStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_STOCK_MOVEMENTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit StockMovement",
      components: { default: EditStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_STOCK_MOVEMENTS,
      },
    },
    {
      path: "view/:id",
      name: "View StockMovement",
      components: { default: ViewStockMovementPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_STOCK_MOVEMENTS,
      },
    },
  ],
};

let unitOfMeasureManagementPages = {
  path: "unit-of-measures",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List UnitOfMeasures",
      components: { default: ListUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_PRODUCTS,
      },
    },
    {
      path: "add",
      name: "Add UnitOfMeasure",
      components: { default: AddUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_PRODUCTS,
      },
    },
    {
      path: "edit/:id",
      name: "Edit UnitOfMeasure",
      components: { default: EditUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_PRODUCTS,
      },
    },
    {
      path: "view/:id",
      name: "View UnitOfMeasure",
      components: { default: ViewUnitOfMeasurePage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_PRODUCTS,
      },
    },
  ],
};


let categoryEquipmentManagementPages = {
  path: "category-equipments",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List CategoryEquipments",
      components: { default: ListCategoryEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CATEGORIES,
      },
    },
    {
      path: "add",
      name: "Add CategoryEquipment",
      components: { default: AddCategoryEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CATEGORIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit CategoryEquipment",
      components: { default: EditCategoryEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CATEGORIES,
      },
    },
    {
      path: "view/:id",
      name: "View CategoryEquipment",
      components: { default: ViewCategoryEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CATEGORIES,
      },
    },
  ],
};



let equipmentManagementPages = {
  path: "equipments",
  component: InventoryModuleLayout,
  children: [
    {
      path: "",
      name: "List Equipments",
      components: { default: ListEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_ANY_CATEGORIES,
      },
    },
    {
      path: "add",
      name: "Add Equipment",
      components: { default: AddEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_CREATE_CATEGORIES,
      },
    },
    {
      path: "edit/:id",
      name: "Edit Equipment",
      components: { default: EditEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_EDIT_CATEGORIES,
      },
    },
    {
      path: "view/:id",
      name: "View Equipment",
      components: { default: ViewEquipmentPage },
      meta: {
        middleware: authorized,
        permission: permissions.PERM_VIEW_CATEGORIES,
      },
    },
  ],
};





const inventoryModuleRoutes = [
  {
    path: "",
    component: InventoryModuleLayout,
    children: [
      {
        path: "",
        name: "Inventory Page",
        components: { default: InventoryPage },
        meta: { middleware: authenticated },
      },
    ],
  },
  productManagementPages,
  unitOfMeasureManagementPages,
  warehouseManagementPages,
  stockMovementManagementPages,
  supplyRuleManagementPages,
  categoryManagementPages,
  categoryEquipmentManagementPages,
  equipmentManagementPages
];

export default inventoryModuleRoutes;
